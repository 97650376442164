import { contactSubmit } from './contact.js';
import './components/aiSelect.js';
import Slider from "./services/Slider.js";
import FilterUnits from './services/FilterUnits.js';
import Map from "./services/Map.js";

/* eslint-disable */
document.addEventListener('DOMContentLoaded', () => {
	const locationsMap = new Map('[data-map="locations-map"]');
	new FilterUnits('[data-js="filter-rates"]');
	new Slider('[data-slider="reviews"]');

	// Mobile menu
	let menuIcon = document.querySelector(".mobile-btn-wrap");
	let mobileMenu = document.querySelector(".mobile-menu");


	menuIcon.addEventListener("click", function () {
		mobileMenu.classList.toggle("active");
		menuIcon.classList.toggle("active");
	});
}, true);
/* eslint-enable */

document.addEventListener('DOMContentLoaded', () => {
	if(document.getElementById('sc-button')){
		document.getElementById('sc-button').setAttribute('target', '_blank');
		document.getElementById('sc-button').addEventListener('click', function(){
			window.open(
				'https://www.smdservers.net/SLWebSiteTemplate_V2/ReserveMoveIn.aspx?sCorpCode=6NRRCQaZplusvr2wrArpuafPg%3d%3d&sLocationCode=F3e81MY3f4IcaovtqM7F3w%3d%3d'
			);
		});
	}

	if(document.getElementById('wf-main')){
		const linkButtons = document.querySelectorAll('#wf-main .wf-left a');
		linkButtons.forEach(linkButton => {
			linkButton.setAttribute('target', '_blank');
			linkButton.addEventListener('click', function(){
				window.open(
					'https://www.smdservers.net/SLWebSiteTemplate_V2/ReserveMoveIn.aspx?sCorpCode=6NRRCQaZplusvr2wrArpuafPg%3d%3d&sLocationCode=F3e81MY3f4IcaovtqM7F3w%3d%3d'
				);
			});
		});
	}
}, true);

if (document.getElementById('contact_form')) {
	contactSubmit();
}