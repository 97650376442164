/* eslint-disable */
export default class FilterUnits {
	constructor(facilityRatesSelector) {
		this.facilityRatesNode = document.querySelector(facilityRatesSelector);
		this.unitNodes = [];
		this.filter = 'all';
		this.amount = false;
		this.init();
	}

	init() {
		if (!this.facilityRatesNode) {
			return;
		}

		this.unitNodes = [...this.facilityRatesNode.querySelectorAll('[data-js="unit"]')];

		// Initial values
		this.update(this.filter, this.amount);

		// Filters
		const facilityFilters = this.facilityRatesNode.querySelector('[data-js="facility-filters-node"]');

		if (!facilityFilters) {
			return;
		}

		facilityFilters.addEventListener('change', () => {
			const formData = new FormData(facilityFilters);
			const filterName = formData.get('facility-filter');
			this.filter = filterName;
			this.update();
		});

		// Amounts
		const viewMoreUnits = document.querySelector('[data-js="view-more-units"]');

		if (!viewMoreUnits) {
			return;
		}

		viewMoreUnits.addEventListener('click', () => {
			if (this.amount !== false) {
				this.amount = this.amount + 5;
			}
			
			this.update();
		});
	}

	hideUnit(unitNode) {
		unitNode.style.display = 'none';
	}

	showUnit(unitNode) {
		unitNode.style.display = 'grid';
	}

	update(filter = this.filter, amount = this.amount) {
		this.unitNodes.forEach(unit => this.hideUnit(unit));

		let filtered = this.unitNodes;

		if (filter !== 'all') {
			filtered = filtered.filter(unitNode => {
				return unitNode.getAttribute('data-type').includes(this.filter);
			});
		}

		if (
			this.amount !== false
			&& window.innerWidth <= 480
		) {
			filtered = filtered.filter((unitNode, index) => index < this.amount);
		}

		filtered.forEach(unit => this.showUnit(unit));

		if (filtered.length <= 0) {
			this.facilityRatesNode.classList.add('empty');
		} else {
			this.facilityRatesNode.classList.remove('empty');
		}
	}
} 
/* eslint-enable */